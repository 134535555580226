<template>
  <div>
    <NeoPageLayout>
      <div class="addNewSupplier _ptb-30 _plr-20">
        <header>
          <NeoBigTitle :text="$t('customerConfig.add_new_supplier')" />
          <a-steps v-model="current" size="small">
            <a-step :disabled="disabled" :title="$t('customerConfig.supplier_baseInfo')" />
            <a-step :disabled="disabled" :title="$t('companyInfo.price_list')" />
          </a-steps>
          <!-- <a-radio-group v-model="tab">
              <a-radio-button value="baseInfo">{{ $t('customerConfig.supplier_baseInfo') }}</a-radio-button>
              <a-radio-button value="priceList">{{ $t('companyInfo.price_list') }}</a-radio-button>
            </a-radio-group> -->
        </header>

        <main class="_mt-20">
          <AddNewSupplierOne :show="current === 0" @next="secondStep" />
          <AddNewSupplierTwo :show="current === 1" :info="oneData" @prev="firstStep" />
        </main>
      </div>
    </NeoPageLayout>
  </div>
</template>

<script>
import AddNewSupplierOne from './components/AddNewSupplierOne'
import AddNewSupplierTwo from './components/AddNewSupplierTwo'
export default {
  name: 'addNewSupplier',
  components: {
    AddNewSupplierOne,
    AddNewSupplierTwo,
  },
  data() {
    return {
      tab: 'baseInfo',
      tabs: ['baseInfo', 'priceList'],
      current: 0,
      // disabled: window.$g.isDev ? false : true,
      disabled: true,
      oneData: null,
    }
  },
  mounted() {},
  methods: {
    firstStep() {
      this.current = 0
    },
    secondStep(type, data) {
      if (type === 'assign') {
        this.current = 1
        this.oneData = data
      } else {
        this.current = 1
      }
    },
  },
}
</script>

<style lang="less" scoped>
.addNewSupplier {
  ::v-deep {
    .ant-steps {
      width: 370px;
    }
  }
}
</style>
